body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: "arial1";
  src: local("arial1"),
    url(./Assets/Fonts/arial1.ttf) format("opentype");
} */

.dx-toolbar-items-container {
  padding-bottom: 30px;
}

.dx-overlay-content {
  padding-top: 20px;
}