$Primary1: #4acb70;
$Primary2: #036d3c;
$Primary3: #edcee7;
$grey1: #dddddd;
$grey2: #bbbbbb;
$grey3: #707070;

.LoadingBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.spinning_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-icon {
  font-size: 20px;
}

.page-header-box {
  border-bottom: 2px solid $grey1;
  margin-bottom: 20px;
  font-weight: 600;
  color: $grey3;
  font-size: 20px;
  padding-bottom: 15px;
}

.FormSuccessBox {
  color: #58a52c;
  background-color: #f3ffeb;
  border: 1px solid #80f573;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.FormSuccessBox2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormBox {
  max-height: 55vh;
  overflow: scroll;
  overflow-x: hidden;
}

.FormBox::-webkit-scrollbar {
  width: 0px;
}

.FormBox1 {
  background-color: #fafafa;
  border: 1px solid $grey1;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  margin-bottom: 2px;
}

.FormBox2 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.formbtn {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid $grey1;
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  color: gray;
}

.Formtxt1 {
  color: $Primary2;
  font-size: 20px;
  font-weight: 600;
}

.Formtxt2 {
  color: #aaaaaa;
  size: 14px;
}

.Formtxt3 {
  color: $Primary2;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.Formtxt4 {
  color: $Primary1;
}

.Formtxt5 {
  color: #707070;
  font-size: 14px;
  margin-bottom: 5px;
}

.Formtxt6 {
  color: #444444;
  font-size: 15px;
  font-weight: 600;
}

.Formtxt7 {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
}

.Formtxt8 {
  color: #444444;
  font-size: 12px;
  font-weight: 600;
}

.Formtxt9 {
  color: #444444;
  font-size: 12px;
}

.Formtxt10 {
  color: #909090;
  font-size: 20px;
  margin-left: 10px;
}

.Formtxt11 {
  color: $Primary2;
  font-size: 17px;
}

.Header {
  background-color: $Primary2;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header-Box1 {
  display: flex;
  height: 100%;
  align-items: center;
}

.Header-Box2 {
  display: flex;
}

.Header-Box4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Header-Box5 {
  display: flex;
  border-bottom: 2px solid $Primary2;
  width: 98%;
  max-width: 98%;
  overflow: auto;
}

.Tabs {
  color: rgba(2, 109, 60, 0.5);
  font-family: Arial;
  font-weight: bold;
  font-size: 13px;
  margin: 0px 10px;
  padding: 10px 15px;
  cursor: pointer;

  &.active {
    color: rgb(2, 109, 60);
    background-color: rgba(2, 109, 60, 0.16);
  }
}

.Header-txt1 {
  color: #fff;
  font-size: 14px;
  font-family: "Times New Roman";
  font-weight: bold;
  letter-spacing: 1px;
}

.Header-txt2 {
  color: #fff;
  font-size: 14px;
  font-family: "Times New Roman";
  font-weight: bold;
  letter-spacing: 1px;
}

.Header-logo {
  margin-left: 20px;
  height: 36px;
  cursor: pointer;
}

.Header-txt {
  margin-left: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &.active {
    color: $Primary1;
  }
}

.Footer {
  background-color: #f6f6f6;
  border-top: 1px solid #d9d9d9;
  height: 64px;
  display: flex;
  align-items: center;
}

.Footer-txt1 {
  color: #444444;
  font-size: 10px;
}

.Footer-txt2 {
  color: #444444;
  font-size: 10px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.center-box {
  min-height: 82vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.trackBox {}

.trackBox-dot-active {
  background-color: #63d915;
  height: 16px;
  width: 16px;
  border-radius: 100px;
  margin-left: -6px;
}

.trackBox-dot {
  background-color: #bbbbbb;
  height: 16px;
  width: 16px;
  border-radius: 100px;
  margin-left: -6px;
}

.trackBox-box-active {
  border-left: 4px solid #63d915;
  padding: 0px 0px 100px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.trackBox-box {
  border-left: 4px solid #bbbbbb;
  padding: 0px 0px 100px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.trackBox-box-content {
  margin-top: -20px;
  overflow-wrap: break-word;
}

.trackBox-box-content-sub {
  margin-left: 30px;
  margin-top: 20px;
}

.home-screen {
  min-height: 86vh;
  background-color: #f6f6f6;
}

.home-text1 {
  color: #707070;
  font-size: 24px;
  font-weight: 600;
}

.home-text2 {
  color: $Primary2;
  font-size: 48px;
}

.home-text3 {
  color: #707070;
  font-size: 14px;
}

.landing-screen {
  background-image: linear-gradient(to right, $Primary1, $Primary2);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 20px;
}

.landing-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-box2 {
  background: #fff;
  border-radius: 24px;
  padding: 20px;
}

.landing-txt1 {
  font-size: 12px;
}

.landing-txt2 {
  font-size: 18px;
  margin: 20px 10px 20px 10px;
}

.landing-txt3 {
  font-size: 8px;
  margin: 20px 10px 5px 10px;
}

.landing-txt4 {
  font-size: 31px;
  font-family: "Times New Roman";
  font-weight: bold;
}

.landing-txt5 {
  font-size: 22px;
  font-family: "Times New Roman";
  font-weight: bold;
}

.landing-txt6 {
  font-size: 8px;
}

.Navigation-container {
  background-color: #f6f6f6;
  padding: 16px;
  // height: calc(100vh - 64px);
  // overflow: scroll;
  // padding-bottom: 70px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  min-width: 25%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.Navigation-drawerBox {
  background-color: $Primary2;
  height: 100vh;
}

.Navigation-Logo {
  height: 28px;
  margin-left: 20px;
}

.DrawerBox-txt1 {
  font-family: "Times New Roman";
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.4px;
  color: #ffffff;
}

.DrawerBox-txt2 {
  font-family: "Times New Roman";
  color: #ffffff;
  font-size: 8px;
}

.Navigation-devider {
  background-color: #ffffff3d;
  height: 1px;
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 100%;
}

.Navigation-drawerBox2 {
  height: 62px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 24px 5px 24px;
}

.Navigation-Icon1 {
  color: #fff;
  font-size: 30px;
}

.Navigation-drawerBox3 {
  height: calc(100vh - 128px);
  overflow: scroll;
  padding: 24px;
  padding-top: 50px;
  overflow-x: hidden;
}

.Navigation-drawerBox3::-webkit-scrollbar {
  width: 10px;
}

.Navigation-drawerBox4 {
  padding: 14px 10px 10px 10px;
  font-size: 10px;
  color: #fff;
}

.Navigation-drawerItem {
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 0px;
  cursor: pointer;
  color: #fff;

  &.active {
    color: hsla(198, 44%, 81%, 1);
  }
}

.Navigation-Icon2 {
  font-size: 24px;
  margin-right: 24px;
}

.Navigation-text1 {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
}

.Navigation-Header {
  height: 64px;
  border-bottom: 2px solid $Primary2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background-color: #fff;
}

.Navigation-devider2 {
  height: 90%;
  background-color: rgb(235, 235, 235);
  width: 1px;
  margin: 10px;
}

.Navigation-HeaderBox1 {
  padding: 10px;
  color: $Primary2;
  font-size: 20px;
  cursor: pointer;
}

.Navigation-HeaderBox2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: $Primary2;
  font-weight: 600;
  cursor: pointer;
}

.Navigation-Avatar {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}

.Screen-Container {
  background-color: #fff;
  min-height: calc(100vh - 135px);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-height: 80vh;
  overflow: auto;
}

.Screen-Container-Detail {
  background-color: #fff;
  min-height: calc(100vh - 96px);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-height: 80vh;
  overflow: auto;
}

.Screen-Container-Detail::-webkit-scrollbar {
  width: 2px;
}

.Screen-Container-Detail::-webkit-scrollbar-thumb {
  background: $Primary1;
}

.Screen-box {
  flex: 1;
  overflow: hidden;
  padding: 15px;
}

.Screen-box2 {
  flex: 1;
  overflow: hidden;
  padding: 15px 8px;
}

.Screen-Header {
  border-bottom: 2px solid $Primary2;
  // padding: 10px 15px 10px 15px;
  padding: 0px 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Screen-Header2 {
  display: flex;
  justify-content: center;
}

.Screen-Header3 {
  border-bottom: 2px solid $Primary2;
  padding: -1px 15px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Screen-Headertxt1 {
  color: $Primary2;
  font-weight: bold;
  font-size: 19px;
  margin: 15px;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

.Screen-Headertxt2 {
  color: #026d3c;
  font-size: 14px;
  font-family: Arial;
  font-weight: bold;
  opacity: 50%;
  opacity: 0.5;
}

.Screen-HeaderIcon1 {
  cursor: pointer;
}

.Screen-Footer {
  padding: 15px;
  border-top: 1px solid rgb(232, 232, 232);
  display: flex;
}

.Screen-FooterBtn1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $Primary1;
  color: #fff;
  padding: 10px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 600;
  width: 146px;
  height: 40px;
  cursor: pointer;
}

.DataTable-Header {
  font-weight: 600;
  color: #026d3c !important;
  opacity: 1 !important;
  font-style: normal;
}

.DataTable-Link {
  color: $Primary1;
  font-size: 12px;
  font-weight: 700;
}

.gridContainer {
  border-bottom: 1px solid gray;
}

.DashboardScreen {
  overflow: auto;
  max-height: 90vh;
}

.DashboardScreen::-webkit-scrollbar {
  display: none;
}

.DashboardScreen-box1 {
  width: 100%;
  min-height: 252px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.DashboardScreen-box2 {
  padding: 25px 40px;
  background: #e0eee9;
}

.DeailsScreen-box2 {
  // padding: 25px 40px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 105px;
}

.DashboardScreen-box3 {
  padding: 20px;
  width: 100%;
}

.DashboardScreen-box4 {
  min-height: 160px;
  max-width: 100%;
  // width: 400px;
  display: flex;
  flex-direction: row;
  border: 1px solid #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  margin-bottom: 20px;
}

.DashboardScreen-txt1 {
  font-size: 17px;
  font-weight: bold;
  color: #026d3c;
}

.DashboardScreen-txt2 {
  font-size: 12px;
  color: #878787;
}

.DashboardScreen-txt3 {
  font-size: 46px;
  letter-spacing: 0px;
  color: #444444;
}

.CardCount {
  border-radius: 8px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 20px;
}

.CardCount-txt1 {
  font-size: 46px;
  text-align: center;
  letter-spacing: 0px;
  color: #444444;
}

.CardCount-txt2 {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: #444444;
}

.CardItem {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(244, 244, 244);
  overflow: hidden;
  background-color: #fff;
  height: 120px;
  max-width: 100%;
  // width: 360px;
}

.CardItem-box1 {
  padding: 40px;
  background-color: $Primary1;
  margin-right: 20px;
  font-size: 50px;
  width: 120px;
  // height: 100%;
}

.CardItem-txt1 {
  font-size: 14px;
  font-weight: 600;
  color: $Primary2;
}

.CardItem-txt2 {
  font-size: 20px;
  font-weight: bold;
  color: $Primary2;
  margin-top: 5px;
  margin-bottom: 5px;
}

.CardItem-txt3 {
  color: #aaaaaa;
  font-size: 12px;
}

.UserPopup {
  margin-top: -20px;
}

.UserPopup-item {
  display: flex;
  margin-bottom: 20px;
}

.UserPopup-icon {
  color: $Primary2;
  font-size: 15px;
  font-weight: bold;
}

.UserPopup-text {
  color: $Primary2;
  font-size: 15px;
  padding-left: 10px;
  font-weight: bold;
  cursor: pointer;
}

.dx-datagrid-headers {
  background-color: rgba(2, 109, 60, 0.16);
  // color: #fff !important;
  padding-top: 10px;
  border: 0px;
}

.DataTable-Header-Icon {
  color: $Primary2;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.SearchPopup-Box {
  height: 100%;
  flex: 1;
  margin-bottom: 70px;
}

.SearchPopup-title {
  color: $Primary2;
  font-size: 20px;
  font-weight: 600;
}

.dx-item-content {
  color: $Primary2;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 30px;
}

.popupButton {
  overflow: visible !important;
}

// start:- Datagrid Dropdown Style

.datagrid-dropdown {
  .datagrid-dropdown-toggle {
    background: transparent;
    border: unset;
    color: $Primary2;

    &::after {
      display: none;
    }

    &:first-child:active,
    &:first-child:hover,
    &:focus-visible {
      background: transparent;
      border: unset;
      box-shadow: none;
      color: $Primary2;
    }
  }

  .datagrid-dropdown-menu {
    border-color: $Primary2;
    padding: 0px;
  }

  .datagrid-dropdown-item {
    border-bottom: 1px solid $grey1;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #444444;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: transparent;
      color: #8f0172;
    }
  }
}

.datagrid-action-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .datagrid-action-icon {
    color: #07aaff;
  }

  .datagrid-action-content {
    padding-left: 10px;
    color: #07aaff;
    font-size: 12px;
    font-weight: bold;
  }
}

.datagrid-height {
  height: calc(100vh - 300px);
  font-size: 12px !important;
  text-transform: capitalize;
}

.datagrid-height-details {
  height: calc(100vh - 330px);
}

// End:- Datagrid Dropdown Style

.btn-advancesearch {
  color: $Primary2 !important;
  font-size: 14px !important;
}

// Start:- Dx Scrollbar Style Change

.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
  background-color: $Primary2 !important;
}

.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
  background-color: $Primary2 !important;
}

.dx-scrollable-scroll-content {
  background-color: $Primary2 !important;
}

// End:- Dx Scrollbar Style Change

// Start:- Dx checkbox Style Change

.dx-checkbox-container {
  overflow: visible !important;
  width: 90% !important;
  height: 90% !important;
}

.dx-checkbox-icon {
  height: 14px !important;
  width: 14px !important;
}

// End:- Dx checkbox Style Change

.RadioBoxBtn {
  background-color: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  color: gray;
  font-size: 14px;
  margin: 10px;
  margin-left: 0px;
  width: 100%;

  &.active {
    color: #3485ff;
    background-color: #effaff;
    border: 1px solid #3485ff;
  }
}

.RadioBoxBtnIcon {
  margin-right: 10px;
  font-size: 20px;
}

.RadioBoxBtn2 {
  background-color: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 18%;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  align-content: center;
  align-items: center;
  padding-left: 2%;
  cursor: pointer;

  &.active {
    color: #000;
    background-color: #e0eee9;
    border: 1px solid #026d3c;
  }
}

.rowbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FormPackageBox {
  background-color: #fafafa;
  border: 1px solid $grey1;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.NewApplication-box1 {
  display: flex;
  justify-content: space-around;
  overflow: auto;
}

.NewApplication-box2 {
  border-radius: 8px;
  background-color: #fafafa;
  border: 1px solid #cccccc;
  width: 150px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.NewApplication-box3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 1px solid #cccccc;
  background: #efefef;
  border-radius: 8px;
}

.NewApplication-txt1 {
  font-family: Arial;
  font-weight: bold;
  font-size: 12px;
}

.NewApplication-txt2 {
  font-size: 12px;
  font-family: Arial;
  // color: rgba(112, 112, 112, 0.6);
  color: black;
  font-weight: bold;
}

.NewApplication-txt3 {
  font-size: 11px;
  font-family: Arial;
  color: #707070;
}

.NewApplication-txt4 {
  font-size: 14px;
  font-family: Arial;
  color: #707070;
}

.NewApplication-txt5 {
  color: #026d3c;
  font-family: Arial;
  font-weight: bold;
  font-size: 17px;
}

.NewApplication-txt6 {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  letter-spacing: 0.34px;
}

.NewApplication-txt7 {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.34px;
}

.NewApplication-txt8 {
  display: flex;
  justify-content: flex-end;
  font-family: Arial;
  font-size: 8px;
  font-weight: bold;
  color: #707070;
}

.NewApplication-txt9 {
  font-size: 11px;
  font-family: Arial;
  color: rgba(112, 112, 112, 0.6);
}

.NewApplication-txt10 {
  font-size: 14px;
  // font-family: Arial;
  color: rgba(112, 112, 112, 0.9);
}

.NewApplication-txt11 {
  font-size: 18px;
  color: #000;
}

.ApplicationDetails-box2 {
  width: 27.5%;
}

// .ApplicationDetails-box3 {
//   // border: 1px solid #dddddd;
//   // width: 35%;
//   // height: 66.5vh;
//   // border-radius: 8px;
//   // overflow: auto;
//   // padding: 25px;
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   height: 66.5vh;
//   overflow: auto;
//   padding: 25px;
//   width: 35%;
// }
.ApplicationDetails-box3 {
  border: 1.5px solid #8d8d8d;
  width: 35%;
  // height: 51.5vh;
  border-radius: 8px;
  padding: 25px;
  overflow: auto;
}

.ApplicationDetails-box3::-webkit-scrollbar {
  width: 2px;
}

.ApplicationDetails-box3::-webkit-scrollbar-thumb {
  background: $Primary1;
}

.ApplicationDetails-box4 {
  // width: 392px;
  height: 80px;
  background: #e0eee9 0% 0% no-repeat padding-box;
  border: 1px solid #026d3c;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0.7%;
  width: 24%;
}

.ApplicationDetails-box5 {
  // width: 392px;
  min-height: 40vh;
  border: 1.5px solid #8d8d8d;
  border-radius: 8px;
  padding: 25px;
}

.ApplicationDetails-box6 {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.ApplicationDetails-box-upload {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.ApplicationDetails-txt2-direction {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  /* align-content: flex-start; */
  width: 50%;
}

.ApplicationDetails-txt3-direction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ApplicationDetails-box7 {
  // width: 100%;
  min-height: 120px;
  height: 100%;
  display: flex;
  border: 1px solid #dddddd;
  min-width: 355px;
  width: 360px;
}

.ApplicationDetails-box8 {
  width: 30%;
  height: 100%;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-right: 1px solid #dddddd;
}

.ApplicationDetails-box9 {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ApplicationDetails-box10 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.ApplicationDetails-txt1 {
  // font-family: Arial;
  letter-spacing: 0px;
  color: #026d3c;
  font-weight: bold;
  font-size: 15px;
}

.ApplicationDetails-txt2 {
  // font-family: Arial;
  color: #707070;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: normal;
}

.ApplicationDetails-txt3 {
  // font-family: "Arial";
  color: #707070;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0px;
}

.ApplicationDetails-txt4 {
  font-weight: bold;
  color: #026d3c;
  font-size: 16px;
}

.ApplicationDetails-txt5 {
  color: #878787;
  font-size: 12px;
}

.ApplicationDetails-txt6 {
  // font-family: Arial;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0px;
  color: #bbbbbb;
}

.ApplicationDetails-txt7 {
  // font-family: Arial;
  font-size: 12px;
  letter-spacing: 0px;
  color: #444444;
}

.ApplicationDetails-txt8 {
  // font-family: Arial;
  font-size: 10px;
  color: #aaaaaa;
}

.ApplicationDetails-txt9 {
  // font-family: Arial;
  color: rgba(68, 68, 68, 1);
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: normal;
}

.ApplicationDetails-txt10 {
  // font-family: Arial;
  color: rgba(68, 68, 68, 1);
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: bold;
}

.ApplicationDetails-txt11 {
  color: #828282;
}

.ApplicationDetails-txt12 {
  color: $Primary2;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ApplicationDetails-ver-line {
  border: 1px solid #dddddd;
  opacity: 1;
  margin: 30px 0;
}

.scrollable-body {
  height: 47vh;
  overflow: auto;
  padding-right: 2%;
}

.scrollable-body::-webkit-scrollbar {
  width: 2px;
}

.scrollable-body::-webkit-scrollbar-thumb {
  background: $Primary1;
}

.scrollable-body::-webkit-scrollbar-thumb:hover {
  background: $Primary2;
}

.Navigation-txt2 {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .center-box {
    min-height: 80vh;
  }

  .home-text1 {
    font-size: 18px;
  }

  .home-text2 {
    font-size: 38px;
  }

  .Header-Box2 {
    display: none;
  }

  .Header-Box3 {
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(211, 211, 211, 0.156);
    border-radius: 100px;
    height: 35px;
    width: 35px;
  }

  .Header-txt {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey1;
  }

  .Navigation-mbileOpen {
    background-color: $Primary2;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -15px;
  }

  .Navigation-txt {
    display: none;
  }

  .Navigation-drawer {
    display: none;
  }

  .Navigation-drawerBox {
    background-color: $Primary2;
  }

  .Footer-txt1 {
    text-align: center;
  }

  .Footer-txt2 {
    text-align: center;
  }

  .ApplicationDetails-box1 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .ApplicationDetails-box2 {
    width: 100%;
    margin: 5% 5% 0% 0%;
  }

  .ApplicationDetails-box3 {
    width: 100%;
    margin: 5% 5% 0% 0%;
  }
}

@media only screen and (min-width: 768px) {
  .Header-Box3 {
    display: none;
  }
}

//ant custom styling

.Register-Card .ant-card-head {
  border-bottom: 0px !important;
}

.Register-Card>.ant-card-head {
  border-bottom: 0px !important;
}

.Register-Card .ant-select-selector {
  height: 40px !important;
}

.ant-upload.ant-upload-drag {
  height: 5vh;
}

.display_flex {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
}

.space_between {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}

.flex_row {
  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
}

.justify_end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
}

.justify_center {
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
}

.flex_flow {
  flex-flow: wrap;
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
}

.flex_align_center {
  align-items: center;
  -webkit-align-items: center;
}

.margin_right_10 {
  margin-right: 10px;
}

.margin_right_12 {
  margin-right: 12px;
}

.margin_right_20 {
  margin-right: 20px;
}

.margin_bottom_4 {
  margin-bottom: 4%;
}

.margin_bottom_10 {
  margin-bottom: 10px;
}

.margin_bottom_20 {
  margin-bottom: 20px;
}

.margin_top_20 {
  margin-top: 20px;
}

.margin_bottom_1_5 {
  margin-bottom: 1.5%;
}

.width_100 {
  width: 100%;
}

.width_50 {
  width: 50%;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

.right_10 {
  right: 10px;
}

.border_bottom_left_radius_8 {
  border-bottom-left-radius: 8px;
}

.border_top_left_radius_8 {
  border-top-left-radius: 8px;
}

.height_100P {
  height: 100%;
}

.flex_column {
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
}

.timeline_status {
  margin-top: 24px;
}

.time_line_tick_outer {
  border-radius: 50%;
  position: absolute;
  left: 25px;
  top: 0;
  border: 2px solid;
  background-color: #ffffff;
  width: 16px;
  height: 16px;
}

.green_border_color {
  border-color: #3cb400;
}

.status_selected_arrow {
  position: absolute;
  right: -10px;
  display: none;
}

.padding_lr_48 {
  padding-left: 40px;
  padding-right: 40px;
}

.padding_lr_20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding_20 {
  padding: 20px;
}

.padding_15 {
  padding: 15px;
}

.width_35 {
  width: 35%;
}

.margin_auto {
  margin: auto
}

.border_bottom_grey_1 {
  border-bottom: 1px solid $grey1;
}

.left_chat_box {
  // width: 392px;
  height: auto;
  background: #e0eee9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: inline-block;
  // display: flex;
  // flex-wrap: wrap;
  // align-content: center;
  // padding: 0.7%;
  width: auto;
  // min-width: 50%;
  margin-right: 20px;
  max-width: 70%;
}

.right_chat_box {
  // width: 392px;
  height: auto;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: inline-block;
  // display: flex;
  // flex-wrap: wrap;
  // align-content: center;
  // padding: 0.7%;
  width: auto;
  margin-left: 20px;
  max-width: 70%;
  // width: 50%;
  // min-width: 50%;
}

.overflow_y {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.message_circle {
  height: 18px;
  width: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #4acb70;
  border-radius: 50%;
  text-align: center;
  color: white;
  left: 17px;
  top: -10px;
}

.message_count_circle {
  height: 18px;
  width: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #4acb70;
  border-radius: 50%;
  text-align: center;
  color: white;
  left: 17px;
  top: -10px;
}

.table_message_count_circle {
  height: 24px;
  width: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #4acb70;
  border-radius: 50%;
  text-align: center;
  color: white;
  left: 17px;
  top: -10px;
}

.custom-select.ant-select-selection.ant-select-selection--single {
  border-radius: 0px 8px 8px 0px;
  height: 53px;
}

.circle_text {
  display: flex;
  font-size: 10px;
  align-items: center;
  text-align: center;
  top: 15%;
  left: 10%;
}

.align_center {
  align-items: center
}

.scroll-bar {
  width: 10px;
  height: 100%;
  right: 0;
  top: 0px;
  position: absolute;
  border-radius: 7px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.chat_content {
  overflow: auto;
  min-height: 50vh;
  max-height: 55vh;
}

.chat_content::-webkit-scrollbar {
  width: 2px;
}

.chat_content::-webkit-scrollbar-thumb {
  background: $Primary1;
}

.float_right {
  float: right
}

.chat_actor_name {
  font-size: 10px;
  color: #aaaaaa;
}

.status_select_box {
  border-radius: 10px;
  width: 100px;
  padding: 1px;
}

.history_content {
  overflow: auto;
  min-height: 50vh;
  max-height: 55vh;
}

.history_content::-webkit-scrollbar {
  width: 2px;
}

.history_content::-webkit-scrollbar-thumb {
  background: $Primary1;
}

.font_8 {
  font-size: 8px;
}

.font_11 {
  font-size: 11px;
}

.font_weight_600 {
  font-weight: 600;
}

.notification_circle {
  height: 21px;
  width: 21px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ff0000;
  border-radius: 50%;
  text-align: center;
  color: white;
  left: 21px;
  top: -1px;
}

.margin_lr_8 {
  margin-right: 8px;
  margin-left: 8px;
}

.text_align_right{
  text-align: right !important;
}

//end